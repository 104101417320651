import { useState, useEffect } from "react"

export const chunk = (array, groupSize) => {
  const groups = []
  for (let i = 0; i < array.length; i += groupSize) {
    groups.push(array.slice(i, i + groupSize))
  }
  return groups
}

export const sum = array =>
  array.reduce((accumulator, currentValue) => accumulator + currentValue)

export const carouselFormatters = {
  getAltText: ({ data, index }) => data.caption || `${index + 1}. picture`,

  getNextLabel: ({ currentIndex, views }) =>
    `Show the ${currentIndex + 2}. picture from ${views.length}`,
  getPrevLabel: ({ currentIndex, views }) =>
    `Show the ${currentIndex}. picture from ${views.length}`,

  getNextTitle: () => "Next",
  getPrevTitle: () => "Previous",

  getCloseLabel: () => "Close",
  getFullscreenLabel: ({ isFullscreen }) =>
    isFullscreen ? "Exit from full screen" : "Switch to full screen",
}

export function useWindowSize() {
  const isClient = typeof window === "object"

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) {
      return false
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}
