import { graphql, StaticQuery } from "gatsby"
import React from "react"
import GalleryMasonry from "./GalleryMasonry"

const GalleryMasonrySection = () => {
  return (
    <section>
      <StaticQuery
        query={graphql`
          {
            allGalleryYaml {
              edges {
                node {
                  title
                  author
                  image {
                    id
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                        originalImg
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <GalleryMasonry
            images={data.allGalleryYaml.edges.map(({ node }) => ({
              id: node.image.id,
              ...node.image.childImageSharp.fluid,
              caption: `${node.title} – ${node.author}`,
            }))}
            itemsPerRow={[2, 4]}
          />
        )}
      />
    </section>
  )
}

export default GalleryMasonrySection
