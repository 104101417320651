import React from "react"
import Img from "gatsby-image"
import Modal from "react-responsive-modal"
import YouTube from "react-youtube"
import PlayIcon from "./PlayIcon"
import "./Video.scss"
import "./YoutubeModal.scss"

export default function Video({ data: { promo_thumbnail } }) {
  const [open, setOpen] = React.useState(false)

  return (
    <section id="video">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2 px-0">
            <a
              className="d-block shadow-lg"
              href="/#"
              onClick={() => setOpen(true)}
            >
              <Img
                fluid={promo_thumbnail.childImageSharp.fluid}
                alt="Matyas playing bass video"
                className="img-fluid rounded-lg"
              />
              <PlayIcon />
            </a>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        center
        focusTrapped={false}
        showCloseIcon={false}
        classNames={{
          overlay: "modal-overlay",
          modal: "modal-content",
        }}
      >
        <YouTube
          videoId="wpezxskZ9IQ"
          opts={{
            height: "390",
            width: "700",
            playerVars: {
              autoplay: 1,
            },
          }}
        />
      </Modal>
    </section>
  )
}
