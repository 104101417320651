import React from "react"
import Img from "gatsby-image"
import "./About.scss"
import about_title from "../../images/about/about_title.svg"

const About = ({ data }) => {
  return (
    <section id="about">
      <div className="container lg-p-5">
        <div className="row p-1">
          <div className="col align-self-center text-center pt-5">
            <h2 className="text-white text-center text-uppercase">About</h2>
            <img src={about_title} alt="title" className="pt-1" />
          </div>
        </div>
        <div className="row p-1 pb-sm-0 p-md-5">
          <div className="col-12 py-5">
            <p className="text-white lead">
              Matyas Hofecker is recognized as one of the leading musicians
              among the younger generation of jazz bassists in Europe. The
              London-based musician acknowledges to be mainly influenced by Paul
              Chambers, Sam Jones, George Duvivier and Peter Washington.
              Hofecker has toured with{" "}
              <span className="font-weight-bold">Harold Mabern Trio</span>,
              recorded an album with{" "}
              <span className="font-weight-bold">Sheila Jordan</span>, and
              continuously shares the stage with great musicians like{" "}
              <span className="font-weight-bold">
                Joe Farnsworth, Jesse Davis, Pete Van Nostrand
              </span>{" "}
              or <span className="font-weight-bold">Jim Rotondi</span> with a
              passion for straight-ahead jazz. He performs regularly around
              Europe, including Spain, Austria, Germany, Switzerland or
              Portugal.
            </p>
          </div>
          <div className="col-sm-10 col-md-6 col-lg-5">
            <div className="p-2">
              <h4 className="text-primary text-uppercase">Passion for music</h4>
              <p className="text-light">
                Matyas Hofecker was born in Hungarian city Pécs, the European
                capital of culture in 2010, and had a desire for music at a very
                young age, playing the trumpet at the age of four. In elementary
                school, he became familiar with jazz and bass guitar. Hofecker,
                then switched to double bass in his teenage years and has
                started playing jazz professionally.
              </p>
            </div>
            <div className="p-2">
              <h4 className="text-primary text-uppercase">Academy of Music</h4>
              <p className="text-light">
                Hofecker studied music at{" "}
                <span className="font-weight-bold">
                  Franz Liszt Academy of Music
                </span>{" "}
                in Budapest. In 2012, he won a scholarship at{" "}
                <span className="font-weight-bold">
                  University of Music and Performing Arts Graz
                </span>{" "}
                , where he studied under the tutelage of Wayne Darling, and
                received his bachelor degree with distinction in 2013. Upon
                continuing his studies, the bassist became a graduate student of{" "}
                <span className="font-weight-bold">
                  Music and Arts Private University of Vienna
                </span>
                . He has worked with{" "}
                <span className="font-weight-bold">Jeremy Pelt</span> and{" "}
                <span className="font-weight-bold">Gregory Hutchinson</span> and
                played different concerts with them at the University.
              </p>
            </div>
          </div>
          <div className="col-sm-10 col-md-6 col-lg-6 p-2 offset-lg-1 d-none d-md-block">
            <Img
              fixed={data.matyas.childImageSharp.fixed}
              alt="Matyas playing bass"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
