import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import Carousel from "react-multi-carousel"
import "./Music.scss"
import music from "./MusicData"
import music_title from "../../images/music/music_title.svg"

const caroulBreakpoints = {
  mobile: {
    breakpoint: {
      max: 767,
      min: 0,
    },
    items: 1,
  },
  tablet: {
    breakpoint: {
      max: 1023,
      min: 768,
    },
    items: 2,
  },
  tabletWide: {
    breakpoint: {
      max: 1279,
      min: 1024,
    },
    items: 3,
  },
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1280,
    },
    items: 4,
  },
}

export default function Music({ data }) {
  debugger
  const [windowDimensions, setWindowDimensions] = useState({})

  useEffect(() => {
    const { innerWidth: width, innerHeight: height } = window
    setWindowDimensions({ width, height })

    function handleResize() {
      setWindowDimensions(() => {
        const { innerWidth: width, innerHeight: height } = window
        return {
          width,
          height,
        }
      })
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const isMobile = windowDimensions.width < 768

  return (
    <section id="music">
      <div className="lg-p-5">
        <div className="p-1 mt-lg-4 text-center">
          <h2 className="text-secondary text-center text-uppercase p-2">
            Music
          </h2>
          <img src={music_title} alt="title" className="pt-1"></img>
        </div>
        <div className="p-1">
          <Carousel
            additionalTransfrom={0}
            arrows
            containerClass="music-swiper"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            keyBoardControl
            removeArrowOnDeviceType={["mobile"]}
            showDots={isMobile}
            responsive={caroulBreakpoints}
            slidesToSlide={1}
            swipeable
          >
            {music.map(({ img, name, url, description }) => (
              <div className="video" key={url}>
                <a
                  href={"https://www.youtube.com/watch?v=" + url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="youtube text-align-center">
                    <Img
                      fixed={data[img].childImageSharp.fixed}
                      alt={description}
                    />
                  </div>
                  <div className="video_text p-3 text-left">
                    <h5 className="text-secondary">{name}</h5>
                    <p className="text-muted">{description}</p>
                  </div>
                </a>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  )
}
