import React from "react"
import "./Reference.scss"
import Img from "gatsby-image"
import qoutes_title from "../../images/reference/qoutes_title.svg"

export default function Reference({ data }) {
  return (
    <section id="reference">
      <div className="container">
        <div className="row p-1 pt-5">
          <div className="col align-self-center text-center">
            <h2 className="text-dark text-center text-uppercase">QUOTES</h2>
            <img src={qoutes_title} alt="title" className="pt-1"></img>
          </div>
        </div>
        <div className="row justify-content-around py-2 py-md-5">
          <div className="col-4 shadow-sm card p-2 pt-4 mb-3">
            <div className="media align-self-center">
              <Img
                fixed={data.quotes1.childImageSharp.fixed}
                alt="Harold Mabern"
                className="rounded-circle img-fluid mr-3"
              />
              <div className="media-body">
                <h5 className="text-dark">Harold Mabern</h5>
                <p className="text-muted">Half Note Athens, 2017</p>
              </div>
            </div>
            <div className="col align-self-left p-2">
              <p className="text-dark text-left">
                “Remember the young bassist’s name; a truly bright future is
                ahead of him”
              </p>
            </div>
          </div>
          <div className="col-4 shadow-sm card p-2 pt-4 mb-3">
            <div className="media align-self-center">
              <Img
                fixed={data.quotes.childImageSharp.fixed}
                alt="Peter Pallai"
                className="rounded-circle img-fluid mr-3"
              />
              <div className="media-body">
                <h5 className="text-dark">Peter Pallai</h5>
                <p className="text-muted">London 606 Club, 2016</p>
              </div>
            </div>
            <div className="col align-self-left p-2">
              <p className="text-dark text-left">
                “Mátyás Hofecker is a sensitive, fast-responsive bassist with an
                extraordinary ear and humility for music.”
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
