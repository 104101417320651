import React from "react"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Fade from "react-reveal/Fade"

import "./Hero.scss"

const Hero = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          hero: file(relativePath: { eq: "hero/hero.png" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={({ hero }) => (
        <BackgroundImage
          Tag="section"
          id="hero"
          fluid={hero.childImageSharp.fluid}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 offset-md-6 pb-5">
                <Fade bottom delay={2000} duration={500}>
                  <h1 className="text-uppercase text-primary text-right mt-5">
                    Matyas Hofecker
                  </h1>
                </Fade>
                <Fade bottom delay={2500} duration={500}>
                  <h4 className="text-white text-right">Jazz bassist</h4>
                </Fade>
              </div>
            </div>
          </div>
        </BackgroundImage>
      )}
    />
  )
}

export default Hero
