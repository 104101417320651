import React from "react"
import "./Footer.scss"
import { FaYoutube, FaSpotify } from "react-icons/fa"

export default function Footer() {
  return (
    <section id="footer">
      <div className="container">
        <div className="row p-1 pt-4">
          <div className="col text-center">
            <a
              className="text-light p-2"
              href="https://www.youtube.com/channel/UCDf0qM9AYIslFi14xr5QnWQ"
              title="Matyas Hofecker - YouTube"
            >
              <FaYoutube />
            </a>
            <a
              className="text-light p-2"
              href="https://open.spotify.com/artist/33aCF0xkNhcTrkV78WtIX8"
              title="Matyas Hofecker - Spotify"
            >
              <FaSpotify />
            </a>
          </div>
        </div>
        <div className="row p-1">
          <div className="col align-self-center">
            <p className="text-muted text-center">
              Copyright &copy; {new Date().getFullYear()}, Matyas Hofecker
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
