export default [
  {
    title: "Bon Viatge",
    author: "Pol Omedes",
    imgKey: "spotify_bon",
    link:
      "https://open.spotify.com/album/3jE0njIJZdppM2KV339v5x?si=sTvh6yhCQbW7F6XeVi4bUA",
  },
  {
    title: "Never Ending Story",
    author: "Matyas Gayer Trio",
    imgKey: "spotify_never",
    link:
      "https://open.spotify.com/album/3J5chffPYGpnVBBQGGnxNL?si=5BdD5gdxRJ6xqiFkaksh4g",
  },
  {
    title: "Labor of Love",
    author: "Lukas Gabric",
    imgKey: "spotify_labor",
    link:
      "https://open.spotify.com/album/6Q1LitmwHBuIjqxSyRD5bl?si=LVnNbHU4Qt-71bAXeIDCbA",
  },
  {
    title: "Mallorca. Pol Omedes with Strings",
    author: "Pol Omedes",
    imgKey: "spotify_mallorca",
    link:
      "https://open.spotify.com/album/3lpBdQlvEFaxoC9U2qLSP0?si=13c-OscCRuWApO-y_SICEg",
  },
  {
    title: "Jive at Five",
    author: "The Coquette Jazz Band",
    imgKey: "spotify_jive",
    link:
      "https://open.spotify.com/album/4IaA9onvK5E85Sa3lNewVh?si=4OzfoGNdTuWcupTKNwzICw",
  },
  {
    title: "New Day Suite",
    author: "Daniel Hofecker 6tet",
    imgKey: "spotify_new",
    link:
      "https://open.spotify.com/album/5YwP3nih421I2Y40ZbpSNa?si=vRoj3PUETj2LM_3oAw3FDA",
  },
]
