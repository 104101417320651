import Img from "gatsby-image"
import React, { useState } from "react"
import Carousel, { Modal, ModalGateway } from "react-images"
import { Link } from "rebass"
import { chunk, sum, carouselFormatters, useWindowSize } from "./utils"
import "./GalleryMasonry.scss"

const GalleryMasonry = ({
  images,
  itemsPerRow: itemsPerRowByBreakpoints = [1],
}) => {
  const aspectRatios = images.map(image => image.aspectRatio)
  const rowAspectRatioSumsByBreakpoints = itemsPerRowByBreakpoints.map(
    itemsPerRow =>
      chunk(aspectRatios, itemsPerRow).map(rowAspectRatios =>
        sum(rowAspectRatios)
      )
  )

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalCurrentIndex, setModalCurrentIndex] = useState(0)
  const { width } = useWindowSize()

  const closeModal = () => setModalIsOpen(false)
  const openModal = imageIndex => {
    setModalCurrentIndex(imageIndex)
    setModalIsOpen(true)
  }

  const calcWidth = (image, i) =>
    rowAspectRatioSumsByBreakpoints.map((rowAspectRatioSums, j) => {
      const rowIndex = Math.floor(i / itemsPerRowByBreakpoints[j])
      const rowAspectRatioSum = rowAspectRatioSums[rowIndex]

      return `${(image.aspectRatio / rowAspectRatioSum) * 100}%`
    })

  return (
    <>
      {images.map((image, i) => (
        <Link
          key={image.id}
          href={image.originalImg}
          onClick={e => {
            e.preventDefault()
            openModal(i)
          }}
        >
          <>
            <Img
              className="masonry-img d-inline-block"
              fluid={image}
              style={{
                width: calcWidth(image, i)[width <= 768 ? 0 : 1],
              }}
            />
          </>
        </Link>
      ))}

      {ModalGateway && (
        <ModalGateway>
          {modalIsOpen && (
            <Modal onClose={closeModal}>
              <Carousel
                views={images.map(({ originalImg, caption }) => ({
                  source: originalImg,
                  caption,
                }))}
                currentIndex={modalCurrentIndex}
                formatters={carouselFormatters}
                components={{ FooterCount: () => null }}
              />
            </Modal>
          )}
        </ModalGateway>
      )}
    </>
  )
}

export default GalleryMasonry
