import React from "react"

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="145"
      height="145"
      fill="none"
      viewBox="0 0 145 145"
    >
      <g filter="url(#filter0_d)">
        <circle cx="72.5" cy="67.5" r="42.5" fill="#E3B873"></circle>
      </g>
      <path
        fill="#fff"
        d="M83 66.268c1.333.77 1.333 2.694 0 3.464l-15 8.66c-1.333.77-3-.192-3-1.732V59.34c0-1.54 1.667-2.502 3-1.732l15 8.66z"
      ></path>
      <defs>
        <filter
          id="filter0_d"
          width="145"
          height="145"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="5"></feOffset>
          <feGaussianBlur stdDeviation="15"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.890196 0 0 0 0 0.721569 0 0 0 0 0.45098 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  )
}

export default Icon
