import React from "react"
import Img from "gatsby-image"
import "./AboutEnd.scss"

const About = ({ data }) => {
  return (
    <section id="aboutend">
      <div className="container pt-sm-0 p-4 pt-md-5">
        <div className="row p-1">
          <div className="col-sm-10 col-md-6 col-lg-6 p-2 d-none d-md-block">
            <Img
              fixed={data.matyas2.childImageSharp.fixed}
              alt="Matyas playing bass"
              className="img-fluid"
            />
          </div>
          <div className="col-sm-10 col-md-6 col-lg-5">
            <div className="text-light p-2">
              <h4 className="text-primary text-uppercase">
                Playing with a Legend
              </h4>
              <p>
                In 2014, the young Hungarian musician was selected among the
                most talented bassists by Generations Jazz Camp and Masterclass
                in Frauenfeld, Switzerland, and participated in a one week
                workshop with{" "}
                <span className="font-weight-bold">
                  Seamus Blake, Don Friedman, Adrian Mears
                </span>{" "}
                and performed on stage with{" "}
                <span className="font-weight-bold">Lewis Nash</span>.
              </p>
              <p>
                Matyas Hofecker was invited to be temporary member of{" "}
                <span className="font-weight-bold">Harold Mabern Trio</span>{" "}
                between 2016-2017 and played together in one of the last tours
                of the pianist legend, alongside the highly regarded drummer,{" "}
                <span className="font-weight-bold">Joe Farnsworth</span>. The
                Trio performed in Greece, Germany and Austria.
              </p>
            </div>
            <div className="text-light p-2">
              <h4 className="text-primary text-uppercase">
                Musicians and Venues
              </h4>
              Hofecker has performed with many of great musicians such as Jesse
              Davis, Grant Stewart, Pete Van Nostrand, John Marshall, Dusko Goykovich, Don
              Menza, Stjepko Gut, Jim Rotondi, Renato Chicco, Mark Taylor or
              Michael Abene and performed on music venues, jazz clubs and
              festivals, to name a few: Morocco Tanjazz Festival Tangier, Half
              Note Athens, Ronnie Scott’s London, Jamboree Barcelona, Jazzkeller
              Frankfurt, Unterfahrt Munich, Hot Club Lisbon, Jazzdock Prague,
              Jazzland Vienna, Montagnola Music Festival Bologna.
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
