import React, { useState, useEffect, useCallback } from "react"
import { debounce } from "lodash"
import Fade from "react-reveal/Fade"
import "./Nav.scss"
import { ScrollTo } from "../ScrollTo"

export default function Nav({ background = false }) {
  const [show, setShow] = useState("")
  const [hasBackground, setHasBackground] = useState(background)

  const btnClass = `navbar-toggler ${show ? "collapsed" : ""}`
  const navClass = `nav-container fixed-top ${
    hasBackground ? "nav-background" : ""
  }`

  const toggleBackground = useCallback(() => {
    if (!hasBackground && window.pageYOffset > 80) {
      setHasBackground(true)
    }
  }, [hasBackground])

  const onMobileMenuToggle = () => {
    setShow(show ? "" : "show")
    if (!hasBackground) {
      setHasBackground(true)
    }
  }

  useEffect(() => {
    toggleBackground()

    window.addEventListener("scroll", debounce(toggleBackground, 50))
  }, [hasBackground, toggleBackground])

  return (
    <div className={navClass}>
      <Fade bottom delay={1000} duration={500}>
        <nav className="navbar navbar-expand-lg navbar-dark container">
          <ScrollTo
            className="navbar-brand text-uppercase text-primary"
            to="about"
            offset={-70}
            title="Home"
          >
            Matyas
            <span className="d-block">Hofecker</span>
          </ScrollTo>
          <button
            className={btnClass}
            type="button"
            onClick={() => onMobileMenuToggle()}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className={`collapse navbar-collapse ` + show}>
            <div className="navbar-nav">
              <ScrollTo
                className="nav-item nav-link px-4 text-uppercase"
                to="hero"
                title="Home"
              >
                Home
              </ScrollTo>
              <ScrollTo
                className="nav-item nav-link px-4 text-uppercase"
                to="about"
                title="about"
              >
                About
              </ScrollTo>
              <ScrollTo
                className="nav-item nav-link px-4 text-uppercase"
                to="music"
                title="Music"
              >
                Music
              </ScrollTo>
              <ScrollTo
                className="nav-item nav-link contact-link text-uppercase btn-primary d-block ml-4"
                to="footer"
                title="Get in touch"
              >
                <span className="button">Get in touch</span>
              </ScrollTo>
            </div>
          </div>
        </nav>
      </Fade>
    </div>
  )
}
