import React from "react"
import BackgroundImage from "gatsby-background-image"

const SpotifyCard = ({ title, author, imgSource, link }) => {
  return (
    <div className="col-12 col-md-6 col-lg-4 mt-3 mb-3">
      <BackgroundImage
        Tag="div"
        className="spotify-album"
        fixed={imgSource.childImageSharp.fixed}
      >
        <a
          href={link}
          title={`${title} - ${author}`}
          className="spotify-card d-block position-absolute"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="text-container text-left position-absolute p-2">
            <h5 className="mb-0 text-light">{title}</h5>
            <p className="mb-0 text-muted">{author}</p>
          </div>
        </a>
      </BackgroundImage>
    </div>
  )
}

export default SpotifyCard
