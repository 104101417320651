import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import {
  Nav,
  Hero,
  About,
  AboutEnd,
  Video,
  Music,
  Spoty,
  Reference,
  Form,
  Footer,
} from "../components/"
import GalleryMasonrySection from "../components/Gallery/GalleryMasonrySection"

const IndexPage = ({ data }) => (
  <>
    <SEO />
    <Nav data={data} />
    <Hero data={data} />
    <About data={data} />
    <GalleryMasonrySection />
    <AboutEnd data={data} />
    <Video data={data} />
    <Music data={data} />
    <Spoty data={data} />
    <Reference data={data} />
    <Form data={data} />
    <Footer data={data} />
  </>
)

export default IndexPage

export const query = graphql`
  {
    matyas: file(relativePath: { eq: "about/About.png" }) {
      childImageSharp {
        fixed(width: 522, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    matyas2: file(relativePath: { eq: "about/matyash2.png" }) {
      childImageSharp {
        fixed(width: 522, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    promo_thumbnail: file(relativePath: { eq: "video/promo_thumbnail.png" }) {
      childImageSharp {
        fluid(maxWidth: 1140) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    quotes1: file(relativePath: { eq: "reference/quotes1.png" }) {
      childImageSharp {
        fixed(width: 78) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    quotes: file(relativePath: { eq: "reference/quotes.png" }) {
      childImageSharp {
        fixed(width: 78) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    video4: file(relativePath: { eq: "music/video4.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    video5: file(relativePath: { eq: "music/video5.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    video6: file(relativePath: { eq: "music/video6.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    video7: file(relativePath: { eq: "music/video7.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    video1: file(relativePath: { eq: "music/video1.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    video2: file(relativePath: { eq: "music/video2.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    video3: file(relativePath: { eq: "music/video3.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    video8: file(relativePath: { eq: "music/video8.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    video9: file(relativePath: { eq: "music/video9.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    video11: file(relativePath: { eq: "music/video11.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    video_00: file(relativePath: { eq: "music/video_00.jpg" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    video_01: file(relativePath: { eq: "music/video_01.jpg" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    play: file(relativePath: { eq: "video/play.png" }) {
      childImageSharp {
        fixed(width: 85, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jamboree: file(relativePath: { eq: "gallery/jamboree.jpg" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    london: file(relativePath: { eq: "gallery/london.jpg" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    e78: file(relativePath: { eq: "gallery/e78.jpg" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    harold: file(relativePath: { eq: "gallery/harold_mabern_trio.jpg" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
