import React from "react"
import { StaticQuery, graphql } from "gatsby"
import "./Spoty.scss"
import SPOTIFY_DATA from "./SpotyData"
import SpotifyCard from "./SpotifyCard"

export default function Spoty() {
  return (
    <StaticQuery
      query={query}
      render={images => (
        <section id="spoty">
          <div className="container py-5">
            <div className="row align-items-center text-center p-3">
              {SPOTIFY_DATA.map(({ title, author, imgKey, link }) => (
                <SpotifyCard
                  key={title}
                  title={title}
                  author={author}
                  link={link}
                  imgSource={images[imgKey]}
                />
              ))}
            </div>
          </div>
        </section>
      )}
    />
  )
}

const query = graphql`
  query {
    spotify_bon: file(relativePath: { eq: "spotify/spotify_bon.jpeg" }) {
      childImageSharp {
        fixed(width: 320) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    spotify_jive: file(relativePath: { eq: "spotify/spotify_jive.jpeg" }) {
      childImageSharp {
        fixed(width: 320) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    spotify_labor: file(relativePath: { eq: "spotify/spotify_labor.jpeg" }) {
      childImageSharp {
        fixed(width: 320) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    spotify_mallorca: file(
      relativePath: { eq: "spotify/spotify_mallorca.jpeg" }
    ) {
      childImageSharp {
        fixed(width: 320) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    spotify_never: file(relativePath: { eq: "spotify/spotify_never.jpeg" }) {
      childImageSharp {
        fixed(width: 320) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    spotify_new: file(relativePath: { eq: "spotify/spotify_new.jpeg" }) {
      childImageSharp {
        fixed(width: 320) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
