export default [
  {
    img: "video_00",
    url: "cnDLmSUhahM",
    name: "Nat Steele's MJQ band with Grant Stewart",
    description: "The Stopper",
  },
  {
    img: "video4",
    url: "wUublkNCCbA&t=834s",
    name: "Harold Mabern Jazz trio",
    description: "Vienna, March 2017.)",
  },
  {
    img: "video7",
    url: "15zIJMtsTAU",
    name: "Matyas Gayer Trio",
    description: "Live in London",
  },
  {
    img: "video6",
    url: "id2uIKWqpao",
    name: "Gayer Mátyás & Hofecker Mátyás duo",
    description: "M5 Hétvégi Belépő II.",
  },
  {
    img: "video1",
    url: "YFVjI3O1Muk",
    name: "Gayer Mátyás trio",
    description: "Cookin At The Continental (Horace Silver)",
  },
  {
    img: "video11",
    url: "2b0zy9VgM2U",
    name: "Harold Mabern Trio",
    description: "Diamonds Are The Girl's Best Friend",
  },
  {
    img: "video5",
    url: "UEiJt6Gy15w",
    name: "Matyas Gayer & Matyas Hofecker",
    description: "Tin Tin Deo (D. Gillespie)",
  },
  {
    img: "video4",
    url: "wpezxskZ9IQ",
    name: "Harold Mabern with his trio in Jazzland",
    description: "Vienna (April 2, 2017)",
  },
  {
    img: "video5",
    url: "RkdVpuS2ZnY",
    name: "Matyas Gayer & Matyas Hofecker",
    description: "Holding Hands (M.Miller)",
  },
  {
    img: "video5",
    url: "CVrgU4RVgtE",
    name: "Matyas Gayer & Matyas Hofecker",
    description: "I Won't Dance (J. Kern)",
  },
  {
    img: "video5",
    url: "m-tSZklprNw",
    name: "Matyas Gayer & Matyas Hofecker",
    description: "Nocturne in Eb Major (F. Chopin)",
  },
  {
    img: "video1",
    url: "zJ6RF6OjERY",
    name: "Gayer Mátyás trio",
    description: "Christmas Blues (Gayer Mátyás)",
  },
  {
    img: "video6",
    url: "W68yPmVNc24",
    name: "Gayer Mátyás & Hofecker Mátyás duo",
    description: "M5 Hétvégi Belépő I.",
  },
  {
    img: "video2",
    url: "Gm_zjzQ6nMw",
    name: "Matyas Gayer Quartet feat Gabor Bolla",
    description: "Love Walked In",
  },
  {
    img: "video3",
    url: "4U5RY2YM-7M",
    name: "Matyas Gayer Trio",
    description: "Stranger in Paradise",
  },
  {
    img: "video8",
    url: "VeTIn-N64t4",
    name: "Matyas Gayer Trio feat. D. Hofecker and A. Komjati",
    description: "Mogie",
  },
  {
    img: "video_01",
    url: "al_OK2lccH4",
    name: "Nat Steele's MJQ band with Grant Stewart",
    description: "In a Sentimental Mood",
  },
  {
    img: "video1",
    url: "YFVjI3O1Muk",
    name: "Gayer Mátyás trio",
    description: "Cookin At The Continental (Horace Silver)",
  },
]
