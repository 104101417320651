import React from "react"
import "./Form.scss"

export default function Form() {
  return (
    <section id="form">
      <div className="container">
        <div className="row contact">
          <div
            id="form-container"
            className="col-12 offset-md-2 col-md-8 text-center rounded-md-lg"
          >
            <h2 className="text-white text-uppercase mt-4">Get in touch</h2>
            <form
              name="contact"
              method="POST"
              action="https://api.web3forms.com/submit"
            >
              <input
                type="hidden"
                name="access_key"
                value="5a323ef1-0f0b-4601-a10a-e91bd493ca71"
              />
              <input
                type="hidden"
                name="redirect"
                value="https://matyashofecker.com/success"
              />
              <div className="form-row pt-5">
                <div className="offset-md-2 col-md-4 mb-3 text-left">
                  <label htmlFor="name" className="text-light">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control text-white"
                    name="name"
                    placeholder="Your name"
                    id="name"
                    required
                  />
                </div>
                <div className="col-md-4 mb-3 text-left">
                  <label htmlFor="email" className="text-light">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control text-white"
                    name="email"
                    placeholder="Your email address"
                    id="email"
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-12 offset-md-2 col-md-8 mb-3 text-left">
                  <label htmlFor="message" className="text-light ">
                    Message
                  </label>
                  <textarea
                    type="text"
                    className="form-control text-white"
                    name="message"
                    placeholder="Your message"
                    id="message"
                    rows="3"
                    required
                  />
                </div>
              </div>
              <div className="form-row text-center align-center">
                <div className="col-12">
                  <button
                    className="text-uppercase btn-primary text-light mt-3 p-2 pl-4 pr-4"
                    type="submit"
                  >
                    Send message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
